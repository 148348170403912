:root{
  --rs-sidenav-default-selected-text: #2b6c7e !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hamburger-react{
  z-index: 10000;
}

.nav-header{
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e3e3e3;
  padding-left: .5em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.table-container{
  width: 100%;
  & .table-container-item{
    width: 100%;
  }
}

.App-Container{
  height: calc(100dvh - 84px);
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 1000px){
  .App-Container{
    height: calc(100dvh - 84px);
  }
}

@media screen and (max-width: 599px){
  h1{
    font-size: 40px;
  }

  .App-Container{
    height: calc(100dvh - 57px - 2em);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
