.logo {
  height: 6em;
}

/* Mobile Ansicht */
@media (max-width: 1000px) {

  .logo {
    height: 4em;
    margin-block: 1em
  }
}